<template>
    <div>
        <page-header :title="`Tổng quan khảo sát`" :items="breadcrumbs" class="mb-3" />
        <btn-change-page class="mb-2" />
        <div v-show="!loading" class="card style-card" v-for="(entry, index) in entries" :key="index">
            <div class="card-body">
                <div class="mb-2 row">
                    <span style="font-size: 18px; font-weight: 600;">Câu hỏi {{ index + 1 }}:</span>
                    <p class="col-lg-7 col-md-10 col-sm col-xs-4" style="font-size: 18px; font-weight: 500;"> {{
                            entry.question
                    }}</p>
                    <div v-if="!checkSeries(series[index])" id="chart" style="margin-left: 25%;">
                        <apexchart type="pie" width="420" :options="chartOptions[index]" :series="series[index]">
                        </apexchart>
                    </div>
                    <i v-else class="text-center col-12" style="font-size: 17px;">Không có dữ liệu</i>
                </div>
            </div>
        </div>
        <!-- loading -->
        <div v-show="loading" class="text-center mt-5">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import btnChangePage from './partials/btn-change-page.vue';
import {
    httpClient
} from "../../../_utils/httpClient";

export default {
    name: 'list',
    components: {
        PageHeader,
        btnChangePage
    },
    data: () => ({
        search: '',
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Khảo sát',
            to: '/admin/surveys'
        },
        {
            text: 'Tổng quan khảo sát',
            to: '#',
            active: true,
        },
        ],
        index: 1,
        docId: 0,
        entries: [],
        rates: {},
        histories: [],
        series: [],
        chartOptions: [],
        loading: true,
    }),
    methods: {
        async getData() {
            this.loading = true
            let response = await httpClient.get(`rate/statistic`, {
                params: {
                    docId: this.docId
                }
            })
            if (response.status === 200 && response.data.code === 0) {
                response.data.data.forEach(item => {
                    if (item.type != 'ShortAnswer')
                        this.entries.push(item)
                });
            } else {
                this.entries = []
            }
            console.log(this.entries);
            this.mapData()
        },
        mapData() {
            this.entries.forEach(() => {
                this.chartOptions.push({
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    legend: {
                        position: 'bottom'
                    },
                    labels: [],
                    responsive: [{
                        breakpoint: 540,
                        options: {
                            chart: {
                                width: 280,
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                })
                this.series.push([])
            });
            this.entries.forEach((ques, index) => {
                ques.listAnswer.forEach(ans => {
                    this.chartOptions[index].labels.push(ans.value)
                    this.series[index].push(ans.count)
                });
            });
            this.loading = false
        },
        checkSeries(series) {
            return series.every(item => item == 0)
        },
        styleChart() {
            document.querySelectorAll('.apexcharts-svg').forEach(el => {
                el.style.overflow = 'inherit'
            })
            document.querySelectorAll('.apexcharts-svg>foreignObject').forEach(el => {
                el.style.overflow = 'inherit'
            })
            document.querySelectorAll('.apexcharts-legend').forEach(el => {
                el.className = ''
                el.style = ''
                el.style.transform = 'translate(100%, 20%)';
                el.style.width = '360px'
            })
        }
    },
    async created() {
        this.docId = this.$route.params.id
        await this.getData();
        window.addEventListener('resize', () => {
            let widthNow = window.innerWidth;
            if (widthNow >= 1258) {
                this.styleChart()
            }
            return;
        });
        let widthNow = window.screen.width;
        if (widthNow >= 1258) {
            this.styleChart()
        }
    },
};
</script>

<style scoped>
.style-time {
    min-width: 110px;
}

.style-sender {
    min-width: 200px;
}

.style-scroll {
    max-height: 300px;
    overflow-y: auto;
}

.style-scroll::-webkit-scrollbar {
    width: 6px;
}

.style-alert {
    padding: 4px 10px;
    margin-bottom: 5px;
    color: black;
    background-color: #eeeef3;
}

.style-alert:hover {
    background-color: #ccc;
    color: #fff
}

.style-card {
    margin-bottom: 20px;
    border: 1px solid #ccc;
}

@media (max-width: 700px) {
    div#chart {
        margin-left: 10% !important;
    }

    .style-h5 {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    div#chart {
        margin-left: 1% !important;
    }
}
</style>
